import React from "react"
import {graphql} from "gatsby"
import EmpleosDetail from "../../components/empleos/EmpleosDetail";
import EmpleosLayout from "../../components/empleos/EmpleosLayout";

const SearchDynamicRoute = ({data}) => {
    const jobPosition = data.searches;
    const pageData = {
        title: jobPosition.position_name,
        description: 'Prueba Descripcion'
    };

    return <EmpleosLayout pageData={pageData}>
        <EmpleosDetail data={jobPosition}/>
    </EmpleosLayout>
}

export const query = graphql`
    query ($id: String) {
        searches(id: { eq: $id}) {
            description_task
            id_search
            name
            position_name
            profile_description
            sector
            seniority_name
            timestamp
            timestamp_approve
        }
    }
`
export default SearchDynamicRoute
